import type {
  ActionEvent,
  ActionEventParams,
  BiTrackingEvents,
  BiTrackingRequestBody,
  BiTrackingResponseData,
  ExperimentAssingedGenericEventParams,
  GenericEvent,
  ImpressionEvent,
  ImpressionEventParams,
  PostalcodeInvalidGenericEventParams,
  PurchaseEvent,
  PurchaseEventParams,
} from "~/types/biTracking";
import { BiTrackingEventType } from "~/types/biTracking";
import { useBackendApiFrontendDomainConfig } from "~/composables/api/useBackendApiFrontendDomainConfig";

export function useBiTrackingClient() {
  const { $pinia } = useNuxtApp();
  const biTrackingStore = useBiTrackingStore($pinia);
  const { apiBaseUrl } = useBackendApiFrontendDomainConfig();

  const { getSessionId } = biTrackingStore;

  const { fetch } = useRequest(apiBaseUrl);

  function trackGeneric(params: ExperimentAssingedGenericEventParams | PostalcodeInvalidGenericEventParams) {
    const event: GenericEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.GENERIC,
      name: params.name ?? "",
      properties: JSON.stringify({ ...params, name: undefined }),
    };
    return trackEvents([event]);
  }

  function trackAction(params: ActionEventParams) {
    const event: ActionEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.ACTION,
      name: params.name + ".answer",
      properties: JSON.stringify({
        selection: params.selection,
        screen_type: params.screenType,
      }),
    };
    return trackEvents([event]);
  }

  function trackImpression(params: ImpressionEventParams) {
    const event: ImpressionEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.IMPRESSION,
      name: params.name ?? "",
    };
    return trackEvents([event]);
  }

  function trackPurchase(params: PurchaseEventParams) {
    const event: PurchaseEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.PURCHASE,
      duration: {
        isoPeriod: params.period,
      },
      sku: params.sku,
      currency: params.currency,
      gateway: params.gateway,
      amountCustomerFacingCurrency: params.amountCustomerFacingCurrency,
    };
    return trackEvents([event]);
  }

  function makeEventSharedParams() {
    return {
      sessionId: getSessionId(),
      date: {
        isoDateTime: new Date().toISOString(),
      },
    };
  }

  function trackEvents(events: BiTrackingEvents) {
    const body: BiTrackingRequestBody = [
      {
        header: biTrackingStore.header,
        events,
      },
    ];

    return fetch<BiTrackingResponseData>("/api/epr", {
      method: "post",
      headers: {
        "Content-Type": "application/vnd.yazio.events+json",
      },
      body: JSON.stringify(body),
    });
  }

  return {
    trackGeneric,
    trackImpression,
    trackPurchase,
    trackAction,
  };
}
