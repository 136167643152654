import { ref } from "vue";
import { defineStore } from "pinia";
import { YearsToSeconds } from "@ilihub/time";
import appConfig from "~/app.config";

import type { QueryParameters } from "~/utils/queryParams";

export const useAwinStore = defineStore(
  "awin",
  () => {
    const dayjs = useDayjs();
    const basketFreezeOverrideList = appConfig.awin.basketFreezeOverrideList;

    const awc = ref<string>("");
    const merchantId = ref<string>(appConfig.awin.defaultMerchantId);
    const affiliateId = ref<string>("");
    const source = ref<string>("web");

    const basketFreezeExpires = ref<string>("");
    const frozenAwc = ref<string>("");

    function isAwinTrackingActive() {
      return awc.value !== "";
    }

    function handleBasketFreeze() {
      // If the affiliateId is in the override list, the current basket freeze will be ignored
      if (isBasketFreezeActive() && !basketFreezeOverrideList.includes(affiliateId.value)) {
        return;
      }

      basketFreezeExpires.value = dayjs().add(20, "minutes").format();
      frozenAwc.value = awc.value;
    }

    function getCurrentAwc() {
      if (isBasketFreezeActive()) {
        return frozenAwc.value;
      }

      return awc.value;
    }

    function isBasketFreezeActive() {
      return frozenAwc.value !== "" && frozenAwc.value !== awc.value && dayjs().isBefore(basketFreezeExpires.value);
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      awc.value = queryParameters.get("awc") ?? awc.value;
      merchantId.value = queryParameters.getAndRemove("awinMerchantId") ?? merchantId.value;
      affiliateId.value = queryParameters.getAndRemove("affid") ?? affiliateId.value;
      source.value = queryParameters.getAndRemove("utm_source") ?? source.value;

      queryParameters.remove("sv_campaign_id");
      queryParameters.remove("sv1");
    }

    return {
      awc,
      source,
      merchantId,
      affiliateId,
      basketFreezeExpires,
      frozenAwc,
      getCurrentAwc,
      isAwinTrackingActive,
      handleBasketFreeze,
      isBasketFreezeActive,
      handleQueryParameters,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: YearsToSeconds(1),
      }),
    },
  },
);
