import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "awin-basket-freeze": () => import("/app/middleware/awinBasketFreeze.ts"),
  "can-access-checkout-page": () => import("/app/middleware/canAccessCheckoutPage.ts"),
  "can-access-finalize-account-page": () => import("/app/middleware/canAccessFinalizeAccountPage.ts"),
  "check-calculator-parameters": () => import("/app/middleware/checkCalculatorParameters.ts"),
  "dummy-query-params-redirect": () => import("/app/middleware/dummyQueryParamsRedirect.ts"),
  "handle-checkout-result": () => import("/app/middleware/handleCheckoutResult.ts"),
  "hide-in-production": () => import("/app/middleware/hideInProduction.ts"),
  "load-subscription-plans": () => import("/app/middleware/loadSubscriptionPlans.ts"),
  "redirect-authenticated-users": () => import("/app/middleware/redirectAuthenticatedUsers.ts"),
  "redirect-unauthenticated-to-login": () => import("/app/middleware/redirectUnauthenticatedToLogin.ts"),
  "validate-registration-params": () => import("/app/middleware/validateRegistrationParams.ts")
}