import * as Sentry from "@sentry/nuxt";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import type { I18n } from "~/types/i18n";

export default defineNuxtPlugin({
  name: "02sentry",
  dependsOn: ["i18n:plugin", "i18n:plugin:switch-locale-path-ssr", "01I18n", "pinia", "pinia-plugin-persistedstate"],
  setup(nuxt) {
    const { $pinia } = useNuxtApp();
    const onboardingStore = useOnboardingStore($pinia);
    const userStore = useUserStore($pinia);
    const i18n = nuxt.$i18n as I18n;
    const biTrackingStore = useBiTrackingStore($pinia);

    function setContext() {
      const context = {
        Sex: onboardingStore.registrationParams?.sex || "-",
        Goal: onboardingStore.registrationParams?.goal || "-",
        Pro: !!(userStore.user && userStore.user?.premium_type),
        Country: onboardingStore.registrationParams?.country || "-",
        Language: i18n?.locale?.value || "-",
        "Session ID": biTrackingStore?.getSessionId() || "-",
        "User UUID": onboardingStore.registrationParams?.userUuid || "-",
        "User Type": userStore.userType || "-",
        SSR: !!nuxt.ssrContext,
      };
      Sentry.setContext("User Context", context);
    }

    if (!nuxt.ssrContext) {
      setContext();

      watchEffect(() => {
        setContext();
      });
    }
  },
});
